var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[ isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" Dashboard ")])])])]}}])}),_vm._l((_vm.history),function(h,i){return _c('router-link',{key:i,attrs:{"to":h},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [(i < 4)?_c('li',{staticClass:"menu-item",class:[ isActive && 'menu-item-active',isExactActive && 'menu-item-active'],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.pathToName(h))+" ")])])]):_vm._e()]}}],null,true)})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }