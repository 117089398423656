<template>
  <ul class="menu-nav">
    <router-link to="/dashboard" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active',isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> Dashboard </span>
        </a>
      </li>
    </router-link>

    <router-link v-for="(h,i) in history" :key="i" :to="h" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[ isActive && 'menu-item-active',isExactActive && 'menu-item-active']" v-if="i < 4">
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> {{pathToName(h)}} </span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  data(){
    return {
      history:[]
    }
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
    capitalize(s){
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    pathToName(path){
      var the = this;
      var tmpPath = path;
      var name = tmpPath.split('/');
      let tmp = "";
      name.forEach((item) => {
        if(item != ""){
          tmp += the.capitalize(item)+' ';
        }
      });
      return tmp;
    }
  },
  watch:{
    $route (to, from){
      if(from.path != '/'){        
        this.history.unshift(from.path);
        this.history = [...new Set(this.history)];
      }
    }
  }
};
</script>
